import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Components/Header/Header";
import "./Home.scss";
import { MasterRouteLanding } from "../../Utilities/Utility";
import { isForgotPasswordAction } from "../../Redux/Actions/actions";

export default function Home() {
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [routes, setRoutes] = useState([]);
    function getMenus() {
        if (userDetails.roleId == 2) {
                let filtered = MasterRouteLanding.filter((c) => c.roleId === 2 && c.menuName != 'Admin');
                setRoutes(filtered);
            }
            if (userDetails.roleId != 2) {
            let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
            setRoutes(filtered);
        }
        //let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
        //setRoutes(filtered);
    }
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;

    window.history.pushState({ path: newurl }, "", newurl);

    useEffect(() => {
        getMenus();
        dispatch(isForgotPasswordAction(false));
    }, []);

    return (
        <>
            <div className="potts-master-block">
                <Header />
                <main className="main-component">
                    {/* <img
            src={Cognisphere}
            alt="Cognisphere"
            className="homepage-cognisphere"
          /> */}
                    {routes.length ? (
                        <div className="bg-landing-page">
                            <Container maxWidth="xl">
                                <Grid container className="homepage-container">
                                    <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                                        <div className="logged-in-user">
                                            <h1 className="logged-in-user-heading">
                                                <span className="logged-in-greetings">Hello,</span>
                                                <span className="logged-in-user-name">{userDetails.firstName}</span>
                                                <span className="logged-in-user-name">{userDetails.userFirstName}</span>
                                            </h1>
                                            <p className="logged-in-project-desc">
                                                Welcome to Routine Test Certificate Tool
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={7}
                                        className="landing-page-menu"
                                    >
                                        <Grid container spacing={2}>
                                            {routes.map((item, index) => (
                                                <Grid item xs={12} sm={6} md={6} key={index}>
                                                    <Link to={item.menuLink} key={index}>
                                                        <div className="menu-item-container">
                                                            <div className="menu-item-text-block">
                                                                <span className="align-center ml-1">
                                                                    {item.menuName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </main>
            </div>
        </>
    );
}
