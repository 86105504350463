import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { saveAzureTokenAction, storeUserDetailsAction } from "../src/Redux/Actions/actions";
import { CustomerRoute, ExternalRoute, MasterRoute, SiemensInternalRoute, SpecialUserRoute } from "./Routes/MasterRoute";
import { ResetPasswordRoute } from "./Routes/ResetPasswordRoute";

import Header from "./Components/Header/Header";

import Loader from "./Components/Loader/Loader";
import {
  authenticateUserBasedOnValidToken,
  validateTheUserBasedOnApi,
} from "../src/APIs/api_Login";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
import IdleTimer from "react-idle-timer";
import { saveAccessTokenAction } from "../src/Redux/Actions/actions";
import getPasswordValidity from "./Components/Header/Header";
import ExtHeader from "./Components/Header/ExtHeader";
// import {
//   authenticateUserWithMyID,
//   generateJwtForAPI,
// } from "./Redux/APIs/api_Login";

import { useMsal } from '@azure/msal-react';

export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [isAuthorized, setIsAuthorized] = useState(true);

  const [isLoader, setIsLoader] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [unAuthText, setUnAuthText] = useState("");
  const isOTPVerified = useSelector((state) => state.isVerifiedOTPReducer);
  const isExternalOTPVerifiedVar = useSelector(
    (state) => state.isExternalOTPVerifiedReducer
  );
  const isForgotPassword = useSelector(
    (state) => state.isForgotPasswordReducer
  );

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setDialogOpen(false);
  };
  function sessionExpire(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    if (auth !== undefined) {
      dispatch(storeUserDetailsAction(""));

      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
  }
  //function authenticateUserWithGID(gid) {

  //}

  // useEffect(() => {
  //   if (auth.user) {
  //     setIsLoader(true);
  //     if (token) {
  //       const arr = auth.user.profile.sub.split("|");
  //       let gid = arr && arr.length ? arr[1] : "";
  //       authenticateUserBasedOnValidToken(dispatch, token, gid);

  //       if (userDetails.userId !== 0) setisAuthorized(true);
  //       setIsLoader(false);
  //     } else {
  //       validateTheUserBasedOnApi(dispatch, auth)
  //         .then((response) => {
  //           setIsLoader(false);
  //         })
  //         .catch((error) => {
  //           setIsLoader(false);
  //           setisAuthorized(false);
  //         });
  //     }
  //   }
  // }, [auth, token, dispatch]);

  function authenticateUserWithAzureAD(dispatch, adToken, gid) {
    authenticateUserBasedOnValidToken(dispatch, adToken, gid)
      .then((response) => {
        if (response) {
          setIsLoader(false);
          setIsAuthorized(false);
        } else {
          setIsAuthorized(false);
          setIsLoader(false);
          setDialogOpen(true);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        setDialogOpen(true);
      });
  }

  useEffect(() => {
    if (activeAccount && isAuthorized && !userDetails.userId) {
      setIsLoader(true);
      dispatch(saveAzureTokenAction(activeAccount.idToken));
      // authenticateUserWithGID();
      authenticateUserWithAzureAD(dispatch, activeAccount.idToken, activeAccount?.idTokenClaims?.gid);
    } else {
      validateTheUserBasedOnApi(dispatch, auth)
        .then((response) => {
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          setIsAuthorized(false);
        });
    }
  }, [instance, activeAccount, userDetails.userId]);


  function logoutClick() {
    dispatch(saveAccessTokenAction(null));
    auth.signoutRedirect();
    window.location = "https://myid.siemens.com/idp/startSLO.ping";
  }

  return (
    <BrowserRouter>
      <div className="ssiat-master-block">
        {isExternalOTPVerifiedVar === true && userDetails.roleId <= 0 ? (
          <div className="potts-master-block">
            <ExtHeader>External Header</ExtHeader>
            <main className="main-component">{ExternalRoute}</main>
          </div>
        ) : isOTPVerified && isForgotPassword ? (
          <div className="potts-master-block">
            <Header>Header Component</Header>
            <main className="main-component">{ResetPasswordRoute}</main>
          </div>
        ) : auth.isLoading || isLoader ? (
          <Loader />
          // ) : (userDetails && userDetails.roleId > 0) || auth.user ? (
        ) : (userDetails && userDetails.roleId > 0) || auth.user ? (
          (userDetails.roleId === 1 ?
            <div className="potts-master-block">
              <Header>Header Component</Header>
              <main className="main-component">
                {MasterRoute}
              </main>
            </div> : userDetails.roleId === 2 ?
              <div className="potts-master-block">
                <Header>Header Component</Header>
                <main className="main-component">
                  {SpecialUserRoute}
                </main>
              </div>
              : userDetails.roleId === 3 ? <div className="potts-master-block">
                <Header>Header Component</Header>
                <main className="main-component">
                  {CustomerRoute}
                </main>
              </div> : userDetails.roleId === 4 ?
                <div className="potts-master-block">
                  <Header>Header Component</Header>
                  <main className="main-component">
                    {SiemensInternalRoute}
                  </main>
                </div> : <></>)
        ) : auth.user && userDetails.roleId == 0 ? (
          <UnauthorizedAccess errorText="You are not allowed to view this page" />
        ) : (
          LoginRoute
        )}
      </div>
      <div>
        <IdleTimer
          timeout={1000 * 60 * 15}
          onIdle={sessionExpire}
          debounce={250}
        />
      </div>
      <>
        <Dialog
          disableEscapeKeyDown
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {auth.error ? "MyID Session Expired" : "Un-Authorized"}
          </DialogTitle>
          <DialogContent>
            <UnauthorizedAccess
              errorText={
                auth.error ? "MyID Error :" + auth.error?.stack : unAuthText
              }
            />
          </DialogContent>
        </Dialog>
      </>
    </BrowserRouter>
  );
}
