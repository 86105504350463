import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { AppBar, IconButton, Toolbar, Tooltip, Zoom } from "@material-ui/core";
import SiemensLogoGreen from "../../assets/images/siemens-logo-petrol.svg";
import "./Header.scss";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import { isExternalOTPVerified } from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appStyle: {
    backgroundColor: `var(--white)`,
    color: `var(--dark-blue)`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    /*display: 'none',*/
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    display: "none",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `transparent`,
  },
  inputRoot: {
    color: "inherit",
    backgroundColor: "var(--light-sand)",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.only("xs")]: {
      width: "2ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  let [appHeaderText] = useState(
    window.innerWidth > 720 ? "RTC Tool" : "RTCTool"
  );

  const logoutExtUser = () => {
    dispatch(isExternalOTPVerified(false));
  };
  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appStyle}>
        <Toolbar>
          <img src={SiemensLogoGreen} alt="Siemens" className="official-logo" />
          <div className={classes.grow}>
            <h2
              className="text-center"
              style={{
                padding: "0 8rem 0 0",
              }}
            >
              {appHeaderText}
            </h2>
          </div>
          <div className={classes.sectionDesktop}></div>
          <Tooltip
            title="Logout"
            TransitionComponent={Zoom}
            enterDelay={100}
            leaveDelay={100}
            onClick={logoutExtUser}
          >
            <IconButton className="header-icon">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
