import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import "./SideDrawer.scss";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: "auto",
    },
});

export default function SideDrawer() {
    const classes = useStyles();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [drawerState, setDrawerState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };

    function logoutUser() {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });

        window.location.href = "/";
    }

    const sideDrawerItems = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Upload RTC Data",
            link: "UploadRTCExcelData",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "View RTC Data",
            link: "viewRTC",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Download RTC",
            link: "generateRTC",
        },
        {
            icon: <GroupAddIcon />,
            title: "Admin",
            link: "userManagement",
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];

    const sideDrawerItemsForVendor = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Upload RTC Data",
            link: "UploadRTCExcelData",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Download RTC",
            link: "generateRTC",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Change Password",
            link: "changePassword",
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <List className="side-drawer-menu-list">
                {sideDrawerItems.map((item, index) =>
                    item.link === "logout" ? (
                        <ListItem button onClick={logoutUser} key={index}>
                            <ListItemIcon className="side-drawer-icons">
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ) : (
                        <Link to={item.link} key={index}>
                            <ListItem button>
                                <ListItemIcon className="side-drawer-icons">
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        </Link>
                    )
                )}
            </List>
            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens Intranet</p>
            </div>
        </div>
    );

    const listForVendor = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <List className="side-drawer-menu-list">
                {sideDrawerItemsForVendor.map((item, index) =>
                    item.link === "logout" ? (
                        <ListItem button onClick={logoutUser} key={index}>
                            <ListItemIcon className="side-drawer-icons">
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ) : (
                        <Link to={item.link} key={index}>
                            <ListItem button>
                                <ListItemIcon className="side-drawer-icons">
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        </Link>
                    )
                )}
            </List>
            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens Intranet</p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    {userDetails.roleId == 4 ? <></> : <img
                        src={DrawerMenuIcon}
                        alt="Besys Menu"
                        className="besys-menu-icon"
                        onClick={toggleDrawer(anchor, true)}
                    />}
                    {userDetails.roleId == 2 ? (
                        <SwipeableDrawer
                            anchor={anchor}
                            open={drawerState[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {listForVendor(anchor)}
                        </SwipeableDrawer>
                    ) : userDetails.roleId == 4 ? (
                        <></>) : (
                        <SwipeableDrawer
                            anchor={anchor}
                            open={drawerState[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    )}

                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
