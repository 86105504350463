import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
//import "./Login.scss";
import SiemensLogo from "../../assets/images/siemens-logo-petrol.svg";
import {
  validateTheUserBasedOnApi,
  authenticateExternalUser,
  generateTokenOnly,
  authenticateCustomer,
} from "../../APIs/api_Login";
import {
  storeUserDetailsAction,
  isVerifiedOTPAction,
  isForgotPasswordAction,
  custHeaderAction,
  isExternalOTPVerified,
} from "../../Redux/Actions/actions";
//import Timer from "../../Components/CountdownTimer/Timer";
import Timer from "../../Components/CountdownTimer/Timer";
//import Loader from "../Components/Loader/Loader";
import Loader from "../../Components/Loader/Loader";
import { forgotUserPassword } from "../../APIs/api_UserManagement";
//import { generateTokenOnly } from "../../Redux/API/api_Login";
//import { gridColumnsTotalWidthSelector } from "@material-ui/data-grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { spocEmail } from "../../Utilities/Utility";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(0, 204, 204, .05)",
    // backdropFilter: "blur(3px)",
    minHeight: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
  },
  errorMessage: {
    color: "var(--red)",
    fontSize: "16px",
  },
  timerDetails: {
    backgroundColor: "var(--bg-green-2)",
  },
  forgotPwd: {
    textAlign: "center",
    textDecoration: "underline",
  },
  loginTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    //backgroundColor: "rgba(0, 204, 204, .05)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "0px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Login() {
  const auth = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const siteToken = useSelector((state) => state.saveTokenReducer);

  // const testRed = useSelector((state) => state.custHeaderReducer);

  const { instance } = useMsal();

  const errMessage = useSelector((state) => state.errorMessageReducer);
  const { control, handleSubmit } = useForm();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userDataTemp, setUserDataTemp] = useState({});
  const [expiredTime, setExpiredTime] = useState(Date.now());
  //const [expiredTime, setExpiredTime] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [emailHelper, setEmailHelper] = useState("");
  const [otpHelper, setOtpHelper] = useState(null);
  const [pwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [forgotPwdEmail, setForgotPwdEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [enterOTPDialogOpen, setEnterOTPDialogOpen] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);

  const [usernameContract, setUsernameContract] = useState("");
  const [extUserEmail, setExtUserEmail] = useState("");
  const [passwordContract, setPasswordContract] = useState("");
  const [emailHelperContract, setEmailHelperContract] = useState("");
  const [passwordHelperContract, setPasswordHelperContract] = useState(null);

  const handleDialogOpen = (content, title) => {
    setDialogTitle(title);
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handlePwdDialogOpen = () => {
    setPwdDialogOpen(true);
    setIsForgotPassword(true);
    dispatch(isForgotPasswordAction(true));
  };
  const handlePwdDialogClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setForgotPwdEmail("");
    setEmailHelper("");
    setPwdDialogOpen(false);
  };

  const handleOTPDialogOpen = () => {
    //setOtpValue("");
    setOtpValue("");
    // dispatch(isExternalOTPVerified(false));
    setEnterOTPDialogOpen(true);
  };
  const handleOTPDialogClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    //setOtpValue("");
    //setOtpHelper("");

    setIsGenReportClicked(false);
    setEnterOTPDialogOpen(false);
  };

  const sendForgotPwdOTP = () => {
    if (isEmailValid) {
      //setForgotPwdEmail('');
      forgotPassword();
    } else {
      setIsEmailValid(false);
      setEmailHelper("Email is mandatory");
    }
  };

  async function forgotPassword() {
    const userData = {
      userEmailId: forgotPwdEmail,
    };
    setIsLoader(true);

    setEmail(userData.userEmailId);

    generateTokenOnly(dispatch)
      .then((tokResponse) => {
        const genToken = tokResponse;
        forgotUserPassword(genToken, userData)
          .then((responseAuthenticate) => {
            if (responseAuthenticate) {
              if (typeof responseAuthenticate === "object") {
                setUserDataTemp(responseAuthenticate);
                setIsLoader(false);
                setOtpValue("");
                setEnterOTPDialogOpen(true);
              } else {
                if (typeof responseAuthenticate === "string") {
                  setIsLoader(false);
                  handleDialogOpen(
                    responseAuthenticate,
                    responseAuthenticate.includes("Failed") ? "Error" : "Alert"
                  );
                } else {
                  setIsLoader(false);
                  handleDialogOpen(
                    "Invalid user data returned from server",
                    "Error"
                  );
                }
              }
            } else {
              setIsLoader(false);
              handleDialogOpen("Invalid email or password", "Error");
            }
          })

          .catch((error) => {
            setIsLoader(false);
            handleDialogOpen("Error in external login", "Error");
          });
      })

      .catch((error) => {
        handleDialogOpen("Error: " + error, "error");
      });
  }

  const handleClose = () => {
    setDialogOpen(false);
    setPwdDialogOpen(false);
  };

  const handleForgotPwdChange = (event) => {
    let emailValid;
    setForgotPwdEmail(event.target.value);
    emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        event.target.value
      );
    if (!emailValid) {
      setIsEmailValid(false);
      setEmailHelper("Invalid Email");
    } else {
      setIsEmailValid(true);
      setEmailHelper("");
    }
  };

  function isValidEmail(value) {
    const exp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return exp.test(value);
  }
  function isValidUsername(value) {
    // upper = /[A-Z]/,
    const minMaxLength = /^[\s\S]{8,}$/,
      letter = /[aA-zZ]/,
      number = /[0-9]/,
      special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;

    return (
      letter.test(value) &&
      number.test(value) &&
      special.test(value) &&
      minMaxLength.test(value)
    );
  }
  function validateUsername(value) {
    if (!value) {
      setEmailHelperContract("*Required");
    } else {
      !isValidUsername(value)
        ? setEmailHelperContract("Invalid Username")
        : setEmailHelperContract(null);
    }
  }
  function validateExtEmail(value) {
    if (!value) {
      setEmailHelperContract("*Required");
    } else {
      !isValidEmail(value)
        ? setEmailHelperContract("Invalid Email")
        : setEmailHelperContract(null);
    }
  }
  function validatePassword(value) {
    if (!value) {
      setPasswordHelperContract("*Required");
    } else {
      setPasswordHelperContract(null);
    }
  }
  const handleChangeContractUserName = (e) => {
    setExtUserEmail(e.target.value);
    validateExtEmail(e.target.value);
  };
  const handleChangeContractPassword = (e) => {
    setPasswordContract(e.target.value);
    validatePassword(e.target.value);
  };

  const [isGenReportClicked, setIsGenReportClicked] = useState(false);
  const handleClickContractLogin = () => {
    if (
      extUserEmail == "" ||
      extUserEmail == null ||
      extUserEmail == undefined ||
      !isValidEmail(extUserEmail)
    ) {
      handleDialogOpen("Enter Valid Email");
      return;
    }
    setIsGenReportClicked(true);
    let loginData = {
      userEmailId: extUserEmail,
      password: "",
    };
    navigatetoExternalReport(loginData);
  };
  function navigatetoExternalReport(loginData) {
    setIsLoader(true);
    validateTheUserBasedOnApi(dispatch, auth)
      //console.log(responseValidate)
      .then((responseValidate) => {
        if (responseValidate.result.response) {
          console.log(responseValidate.result.objResult.token);
          authenticateCustomer(
            responseValidate.result.objResult.token,
            loginData
          )
            .then((responseAuthenticate) => {
              if (responseAuthenticate) {
                if (typeof responseAuthenticate === "object") {
                  setUserDataTemp(responseAuthenticate);
                  console.log("Response Authenticate", responseAuthenticate);
                  setIsLoader(false);
                  // setDialogOpen(true);
                  setOtpValue("");
                  setEnterOTPDialogOpen(true);
                } else {
                  if (typeof responseAuthenticate === "string") {
                    setIsLoader(false);
                    handleDialogOpen(
                      responseAuthenticate,
                      responseAuthenticate.includes("Failed")
                        ? "Error"
                        : "Alert"
                    );
                  } else {
                    setIsLoader(false);
                    handleDialogOpen(
                      "Invalid user data returned from server",
                      "Error"
                    );
                  }
                }
              } else {
                setIsLoader(false);
                handleDialogOpen("Invalid email or password", "Error");
              }
            })
            .catch((error) => {
              setIsLoader(false);
              handleDialogOpen("Error in external login", error);
            });
        }
      })
      .catch((error) => {
        setIsLoader(false);
        handleDialogOpen("Error in token generation", error);
      });
  }

  //function contractUserLogin(loginData) {
  //    setIsLoader(true);
  //    validateTheUserBasedOnApi(dispatch, auth)
  //        .then((responseValidate) => {
  //            if (responseValidate.response) {
  //                authenticateContractUser(responseValidate.objResult.token, loginData)
  //                    .then((responseAuthenticate) => {
  //                        if (responseAuthenticate) {
  //                            if (typeof responseAuthenticate === "object") {
  //                                let userData = {
  //                                    gid: responseAuthenticate.gid,
  //                                    roleId: responseAuthenticate.roleId,
  //                                    roleName: responseAuthenticate.roleName,
  //                                    userEmail: responseAuthenticate.userEmail,
  //                                    userFirstName: responseAuthenticate.userFirstName,
  //                                    userLastName: responseAuthenticate.userLastName,
  //                                    userId: responseAuthenticate.userId,
  //                                    supplierId: responseAuthenticate.supplierId,
  //                                };
  //                                dispatch(storeUserDetailsAction(userData));
  //                                setIsLoader(false);
  //                            } else {
  //                                if (typeof responseAuthenticate === "string") {
  //                                    setIsLoader(false);
  //                                    handleDialogOpen(
  //                                        responseAuthenticate,
  //                                        responseAuthenticate.includes("Failed")
  //                                            ? "Error"
  //                                            : "Alert"
  //                                    );
  //                                } else {
  //                                    setIsLoader(false);
  //                                    handleDialogOpen(
  //                                        "Invalid user data returned from server",
  //                                        "Error"
  //                                    );
  //                                }
  //                            }
  //                        } else {
  //                            setIsLoader(false);
  //                            handleDialogOpen("Invalid email or password", "Error");
  //                        }
  //                    })
  //                    .catch((error) => {
  //                        setIsLoader(false);
  //                        handleDialogOpen("Error in external login", "Error");
  //                    });
  //            }
  //        })
  //        .catch((error) => {
  //            handleDialogOpen("Error in token generation", "Error");
  //        });
  //}

  function myIDLogin() {
    dispatch(auth.signinRedirect);
  }

  function azureADLogin() {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }



  function externalUserLogin(userData) {
    setIsLoader(true);
    setEmail(userData.userEmailId);
    setPassword(userData.password);
    validateTheUserBasedOnApi(dispatch, auth)
      //console.log(responseValidate)
      .then((responseValidate) => {
        if (responseValidate.result.response) {
          console.log(responseValidate.result.objResult.token);
          authenticateExternalUser(
            responseValidate.result.objResult.token,
            userData
          )
            .then((responseAuthenticate) => {
              if (responseAuthenticate) {
                if (typeof responseAuthenticate === "object") {
                  setUserDataTemp(responseAuthenticate);
                  setIsLoader(false);
                  // setDialogOpen(true);
                  setOtpValue("");
                  setEnterOTPDialogOpen(true);
                } else {
                  if (typeof responseAuthenticate === "string") {
                    setIsLoader(false);
                    handleDialogOpen(
                      responseAuthenticate,
                      responseAuthenticate.includes("Failed")
                        ? "Error"
                        : "Alert"
                    );
                  } else {
                    setIsLoader(false);
                    handleDialogOpen(
                      "Invalid user data returned from server",
                      "Error"
                    );
                  }
                }
              } else {
                setIsLoader(false);
                handleDialogOpen("Invalid email or password", "Error");
              }
            })
            .catch((error) => {
              setIsLoader(false);
              handleDialogOpen("Error in external login", "Error");
            });
        }
      })
      .catch((error) => {
        handleDialogOpen("Error in token generation", "Error");
      });
  }

  function VerifyOTP() {
    if (otpValue && userDataTemp.generatedOTP === otpValue) {
      let userData = {
        gid: userDataTemp.gid,
        roleId: userDataTemp.roleId,
        roleName: userDataTemp.roleName,
        // userEmail: userDataTemp.emailId,
        emailId: userDataTemp.emailId,
        userFirstName: userDataTemp.firstName,
        userPassword: userDataTemp.userPassword,
        userLastName: userDataTemp.lastName,
        userId: userDataTemp.userId,
        //supplierId: userDataTemp.supplierId,
        changePassword: userDataTemp.changePassword,
        passwordChangedOn: userDataTemp.passwordChangedOn,
        loginId: userDataTemp.id,
      };
      console.log(userData.roleId);
      dispatch(storeUserDetailsAction(userData));
      dispatch(isVerifiedOTPAction(true));
    } else {
      handleDialogOpen("OTP did not match, try again");
    }
  }

  function verifyExtLoginOTP() {
    if (otpValue && userDataTemp.generatedOTP === otpValue) {
      dispatch(isExternalOTPVerified(true));
      handleDialogOpen("OTP verfied");
    } else {
      handleDialogOpen("OTP did not match, try again");
    }
  }

  const ResendOTP = (e) => {
    handleClose.call();
    handleOTPDialogClose();

    //handleOTPDialogOpen();
    //setOtpValue("");

    let userData = {
      userEmailId: email,
      password: password,
    };
    externalUserLogin(userData);
  };
  const ResendForgotPwdOTP = () => {
    handleOTPDialogClose();
    let userData = {
      userEmailId: email,
    };
    forgotPassword();
  };

  //Related to forgot password
  function VerifyForgotPwdOTP() {
    let userData = {
      //userEmail: userDataTemp.userEmail,
      emailId: userDataTemp.emailId,
      roleId: userDataTemp.roleId,
    };
    if (otpValue && userDataTemp.generatedOTP === otpValue) {
      let userData = {
        gid: userDataTemp.gid,
        roleId: userDataTemp.roleId,
        roleName: userDataTemp.roleName,
        //userEmail: userDataTemp.emailId,
        emailId: userDataTemp.emailId,
        userFirstName: userDataTemp.firstName,
        userPassword: userDataTemp.userPassword,
        userLastName: userDataTemp.lastName,
        userId: userDataTemp.userId,
        //supplierId: userDataTemp.supplierId,
        changePassword: userDataTemp.changePassword,
        passwordChangedOn: userDataTemp.passwordChangedOn,
      };

      dispatch(storeUserDetailsAction(userData));
      dispatch(custHeaderAction("CustomizedHeader"));
      dispatch(isVerifiedOTPAction(true));
      dispatch(isForgotPasswordAction(true));
    } else {
      handleDialogOpen("OTP did not match, try again");
    }
  }

  function handleChangeOtp(event) {
    validateOTP(event.target.value);
    setOtpValue(event.target.value);
  }

  function validateOTP(value) {
    if (!value) {
      setOtpHelper("OTP is mandatory");
    } else {
      const re = /^\d*$/;
      if (!re.test(value)) {
        setOtpHelper("OTP must be integer");
        setIsOtpValid(false);
      } else if (value.toString().length !== 6) {
        setOtpHelper("OTP must be of 6 digit");
        setIsOtpValid(false);
      } else if (value !== userDataTemp.generatedOTP) {
        setOtpHelper("OTP mismatch");
        setIsOtpValid(false);
      } else {
        setOtpHelper(null);
        setIsOtpValid(true);
      }
    }
  }

  useEffect(() => {
    if (enterOTPDialogOpen) {
      setExpiredTime(Date.now() + 180000);
      setTimeout(function () {
        // setEnterOTPDialogOpen(false);
        handleOTPDialogClose();
      }, 180000);
    }
    // isExternalOTPVerified(false);
  }, [enterOTPDialogOpen]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="bg-login-register">
      <div className={classes.root}>
        <Container>
          <Grid container className="pt-lr-mobileContainer">
            <Grid item xs={12}>
              <div className="pt-login-logo-block">
                <img
                  src={SiemensLogo}
                  className="official-logo"
                  alt="Siemens"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={10} md={6} className="pt-lr--project-block">
              {/*    <div className="pt-lr--project">*/}
              {/*        <p className="pt-lr-project-name">*/}
              {/*            RTC - Routine Test Certificate*/}
              {/*        </p>*/}
              {/*        <span className="pt-lr--project-description">*/}
              {/*            Blend of diverse business tools along with reporting based on*/}
              {/*            SAP data*/}
              {/*        </span>*/}
              {/*    </div>*/}
            </Grid>
            <Grid item xs={12} sm={10} md={6} className="pt-lr-master-block">
              <div className={classes.loginTabs}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className="pl-tab-2i"
                      label="Customer"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="AzureAD Users"
                      className="pr-tab-2i"
                      //label="Stage Users"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="pl-tab-2i"
                      label="Special User"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={1}>
                  <div className="pt-login-siemens_user">
                    <h3 className="heading-margin-5tb-0lr">
                      AzureAD Users Login{" "}
                    </h3>
                    <p>If you are a Siemens Employee, login via AzureAD</p>
                    <div className="d-flex jc-center myid-login-btn-block">
                      <Link to="/">
                        {/* <Button
                          className="pt-button--hero myid-login-btn"
                          id="myIDLoginBtn"
                          onClick={myIDLogin}
                        //onClick={auth.signinRedirect}
                        >
                          <span>MyID Login</span>
                        </Button> */}

                        <Button
                          className="pt-button--hero myid-login-btn"
                          id="AzureAdLogin"
                          onClick={azureADLogin}
                        >
                          <span>AD Login</span>
                        </Button>


                      </Link>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="pt-login-external_user">
                    <h3 className="heading-margin-5tb-0lr">Special User</h3>
                    <span>
                      If you are a special user, login via registered
                      email
                    </span>
                    <p className={classes.errorMessage}>{errMessage}</p>
                    <form
                      noValidate
                      autoComplete="off"
                      className="pt-login-form"
                      onSubmit={handleSubmit((data) => externalUserLogin(data))}
                    >
                      <Controller
                        name="userEmailId"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Email ID required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email format",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            type="email"
                            label="Email"
                            variant="filled"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            id="supplierEmail"
                            className="pt-login-form-input mb-1"
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Password required",
                          minLength: {
                            value: 6,
                            message:
                              "Password must be greater than 6 characters",
                          },
                          maxLength: {
                            value: 15,
                            message:
                              "Password cannot be greater than 15 characters",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            type="password"
                            label="Password"
                            variant="filled"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            id="supplierPassword"
                            className="pt-login-form-input mb-1"
                          />
                        )}
                      />

                      <div className="d-flex jc-center supplier-login-btn-block">
                        <Button
                          type="submit"
                          className="pt-button--hero supplier-login-btn"
                          id="SupplierLoginBtn"
                        >
                          Generate OTP
                        </Button>
                      </div>
                    </form>
                  </div>

                  <div className={classes.forgotPwd}>
                    <h5
                      className="forget-pwd-cursor"
                      onClick={handlePwdDialogOpen}
                    >
                      Forgot Password
                    </h5>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={0}>
                  <div className="pt-login-external_user">
                    <h3 className="heading-margin-5tb-0lr">Download RTC</h3>
                    <span>If you are to generate report, enter mail id.</span>
                    <p className={classes.errorMessage}>{errMessage}</p>
                    <TextField
                      fullWidth
                      type="email"
                      label="Email"
                      variant="filled"
                      value={extUserEmail}
                      onChange={handleChangeContractUserName}
                      error={
                        emailHelperContract && emailHelperContract.length > 0
                      }
                      helperText={emailHelperContract}
                      id="contractEmployeeUsername"
                      className="pt-login-form-input mb-1"
                    />

                    <div className="d-flex jc-center supplier-login-btn-block">
                      <Button
                        type="button"
                        className="pt-button--hero supplier-login-btn"
                        id="contractEmployeeLoginBtn"
                        onClick={handleClickContractLogin}
                      >
                        Generate OTP
                      </Button>
                    </div>
                  </div>
                </TabPanel>
                {/*<div className="d-flex jc-center">*/}
                {/*    <span>Having Issues?&nbsp;</span>*/}
                {/*    <span>*/}
                {/*        <a className="contact-us-text" href={"mailto:" + spocEmail}>*/}
                {/*            Contact us*/}
                {/*        </a>*/}
                {/*    </span>*/}
                {/*</div>*/}
              </div>

              {/* <div className="pt-divider">
                  <hr className="dashed" />
                </div> */}
            </Grid>
          </Grid>

          {/* Change Password */}
          <Dialog
            open={pwdDialogOpen}
            onClose={handlePwdDialogClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
            <Grid item xs={12}>
              <DialogContent>

                <TextField
                  autoFocus
                  variant="filled"
                  id="forgotEmail"
                  label="Enter Registered Email ID"
                  type="email"
                  onChange={handleForgotPwdChange}
                  value={forgotPwdEmail}
                  error={emailHelper.length !== 0}
                  helperText={emailHelper}
                  fullWidth
                />

              </DialogContent>
            </Grid>
            <DialogActions>
              <Button
                onClick={handlePwdDialogClose}
                className="pt-button--secondary"
              >
                Cancel
              </Button>
              <Button onClick={sendForgotPwdOTP} className="pt-button--primary">
                Send OTP
              </Button>
            </DialogActions>
          </Dialog>

          {/* verify OTP for Forgot Password */}
          <div>
            <Dialog
              open={enterOTPDialogOpen}
              onClose={handleOTPDialogClose}
              aria-labelledby="otp-dialog-title"
              aria-describedby="otp-dialog-description"
            >
              <div>
                <DialogTitle id="otp-dialog-title">Enter OTP</DialogTitle>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleOTPDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <DialogContent>
                <DialogContentText id="otp-dialog-description">
                  <TextField
                    autoFocus
                    fullWidth
                    type="text"
                    label="Enter OTP"
                    variant="filled"
                    value={otpValue}
                    // value={value ? value.replace(/\D/g, "") : ""}
                    inputProps={{ maxLength: 6 }}
                    onChange={handleChangeOtp}
                    error={otpHelper && otpHelper.length > 0}
                    helperText={otpHelper}
                    id="forgotPwdOTP"
                  />

                  <Box>
                    <span>OTP will be expired in</span>
                    <Timer
                      expiredTime={expiredTime}
                      className={classes.timerDetails}
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={isForgotPassword ? ResendForgotPwdOTP : ResendOTP}
                  type="input"
                  className="pt-button--secondary supplier-login-btn"
                  id="btnOtpResend"
                >
                  Resend
                </Button>
                <Button
                  onClick={
                    isForgotPassword
                      ? VerifyForgotPwdOTP
                      : isGenReportClicked
                        ? verifyExtLoginOTP
                        : VerifyOTP
                  }
                  className="pt-button--primary supplier-login-btn"
                  id="btnOtpVerify"
                  disabled={otpHelper && otpHelper.length > 0 && !otpValue}
                >
                  Verify
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div>
            <Dialog
              open={dialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialogContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  type="input"
                  className="pt-button--secondary supplier-login-btn"
                  id="SupplierOTPResend"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Container>
        {isLoader ? <Loader /> : <></>}
      </div>
    </div>
  );
}
