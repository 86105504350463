import { combineReducers } from "redux";

let tokenInitialState = {
  token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userId: 0,
    loginId: 0,
  },
};
const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};
let userInitialState = {
  gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

let isVerifiedOTPInitialState = false;
const isVerifiedOTPReducer = (state = isVerifiedOTPInitialState, action) => {
  switch (action.type) {
    case "isVerifiedOTP": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};
let isExternalOTPVerifiedInitialState = false;
const isExternalOTPVerifiedReducer = (
  state = isExternalOTPVerifiedInitialState,
  action
) => {
  switch (action.type) {
    case "isExternalOTPVerified": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};
let isUpdateResponse = { response: 0 };
const updateResponseReducer = (state = isUpdateResponse, action) => {
  switch (action.type) {
    case "updatePotential": {
      return { response: action.payLoad + state.response };
    }
    case "updateBudget": {
      return { response: action.payLoad + state.response };
    }
    case "updateManualIndirect": {
      return { response: action.payLoad + state.response };
    }
    default:
      return state;
  }
};

let isForgotPasswordInitialState = false;
const isForgotPasswordReducer = (
  state = isForgotPasswordInitialState,
  action
) => {
  switch (action.type) {
    case "isForgotPassword": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};

let azureToken = {
  token: "",
};
const saveAzureTokenReducer = (state = azureToken, action) => {
  switch (action.type) {
    case "saveAzureToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

export const masterReducer = combineReducers({
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  authUserReducer: authUserReducer,
  updateResponseReducer: updateResponseReducer,
  isVerifiedOTPReducer: isVerifiedOTPReducer,
  isForgotPasswordReducer: isForgotPasswordReducer,
  isExternalOTPVerifiedReducer: isExternalOTPVerifiedReducer,
  saveAzureTokenReducer: saveAzureTokenReducer
});
